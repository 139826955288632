import store from "@/core/services/store";
import router from "@/router";
const BackService = {
  init() {
    this.urlAPI = "https://hub.mobixgroup.com";
    this.user = null;
    store.back = this;

  },
  ajax(route, params = null, callBack) {
    const url = route.search('http') === 0 ? route : this.urlAPI + route;
    let the = this;
    let option = {};
    option.headers = {
      "Content-Type":"application/json",
      Authorization: this.getToken()
    }
    if(params != null){
      option.method = "POST",
      option.body = JSON.stringify(params)
    }
    fetch(url,option).then( response => {
      if(response.status === 401){
        the.destroyToken();
        the.destroyUser();
        router.push({path:'/login'})
      }
      response.json().then( data =>{
        the.res = data;
        if(callBack != null) {
          callBack(the.res);
        }
      })
    }).catch( res => {
      console.res(res);
    });
  },
  getToken(){
    return window.localStorage.getItem("token");
  },
  saveToken(token){
    window.localStorage.setItem("token", token);
  },
  saveUser(token){
    window.localStorage.setItem("user", JSON.stringify(token));
  },
  getUser(){
    return JSON.parse(window.localStorage.getItem("user"));
  },
  destroyToken(){
    window.localStorage.removeItem("token");
  },
  destroyUser(){
    window.localStorage.removeItem("user");
  },
  timestampToDate(timestamp,datetime = true){
    var date = new Date(timestamp*1000);
    var res = date.toLocaleDateString("fr-Fr");
    if(datetime){
      res += ' '+date.toLocaleTimeString("fr-Fr");
    }
    return res;
  },
};

export default BackService;
